import React ,  { Component } from "react";
import logo from "../assets/images/logo/logo.png"

class Navbar extends Component {
    render(){
        return(
            <div className="app-header header--transparent sticker" id="main-menu">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-sm-5 col-5">
                            <div className="logo">
                                <a href={`${process.env.PUBLIC_URL}/`}>
                                    
                                    
                                    <h2 className="logo-1" >
                                        <span className="text-light">
                                            <img width={70}  src={logo} alt="app landing"/>
                                        </span>
                                        <span className="text-light">Nu</span>
                                        <span className="theme-color">sronla</span>
                                        
                                    </h2>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-8 d-none d-lg-block">
                            <div className="mainmenu-wrapper">
                                <nav>
                                    <ul className="main-menu">
                                    <li className="active"><a href="#home">Accueil</a></li>
                                        <li><a href="#about">À-propos</a></li>
                                        <li><a href="#features">Fonctionnalités</a></li>
                                        <li><a href="#pricing">Partenaires</a></li>
                                        {/* <li><a href="#reviews">privé</a></li> */}
                                        
                                        <li><a href="#screenshots">Screenshots</a></li>
                                        <li><a href="#blog">privé</a></li>
                                        <li><a href="#support">Support</a></li>
                                    </ul>
                                </nav>
                                {/* <button className="button-default button-olive" type="button">Download</button> */}
                            </div>
                        </div>
                        <div className="col-sm-7 col-7 d-block d-lg-none">
                            <div className="mobile-menu">
                                <nav>
                                    <ul>                              
                                        <li className="active"><a href="#home">Home</a></li>
                                        <li><a href="#about">About</a></li>
                                        <li><a href="#features">Features</a></li>
                                        <li><a href="#pricing">Pricing</a></li>
                                        <li><a href="#reviews">Reviews</a></li>
                                        <li><a href="#screenshots">Screenshots</a></li>
                                        <li><a href="#support">Support</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Navbar;


