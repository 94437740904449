import React, { Component } from "react";
import banner from "../assets/images/app/eleve6.png";
import ParticlesBg from "particles-bg";
// import logo from "../assets/images/logo/logo.png"


class HeroOlive extends Component {
    render(){
        let config = {
            num: [4, 150],
            rps: 0.5,
            radius: [6, 14],
            life: [1.5, 31],
            v: [2, 3],
            tha: [-40, 40],
            // body: "./img/icon.png", // Whether to render pictures
            rotate: [0, 20],
            alpha: [0.6, 0],
            scale: [1, 0.1],
            position: "all", // all or center or {x:1,y:1,width:100,height:100}
            cross: "dead", // cross or bround
            random: 160,  // or null,
            g: 5,    // gravity
            f: [2, -1], // force
            onParticleUpdate: (ctx, particle) => {
                ctx.beginPath();
                ctx.rect(particle.p.x, particle.p.y, particle.radius * 2, particle.radius * 2);
                ctx.fillStyle = particle.color;
                ctx.fill();
                ctx.closePath();
            }
        };
        return(
            <div className={`slider-area bg-color ${this.props.horizontal} ${this.props.bgshape}`} id="home"  style={{background:"#2d3e50"}}>
                <div className="container h-100">
                    <div className="row">
                        <div className="col-lg-7 h-100">
                            <div className="banner-text">
                                <div className="banner-table-cell">
                                    <h1>
                                        <span className="text-light">NU</span>
                                        <span className="theme-color">SRONLA</span>
                                    </h1>
                                    <h3 className="text-light">L'APPLICATION MOBILE DE MES ETUDES SCOLAIRES</h3>
                                    <p> <em>Avec l'application mobile Nusronla, vous avez avec vous, tout le programme éducatif secondaire de
                                        votre pays, ainsi que toutes les épreuves passées de tous les niveau et de toutes les séries de chaque
                                        établissement scolaire agréé de votre pays. Vous êtes élève de chaque professeur de votre pays.
                                        Chaque professeur peut vous aider à mieux comprendre vos cours et à traiter vos exercices et chaque
                                        professeur
                                        </em>
                                    </p>
                                    <div className="banner-buttons">
                                        <button type="button" className="button-default button-olive">TELECHARGER</button>
                                        <a className="button-default button-white" href="#about" role="button">LIRE PLUS</a>
                                    </div>
                                </div>
                            </div>

                            <div className="banner-apps">
                                {/* Start Sinhle app */}
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <i className="zmdi zmdi-apple"></i>
                                        <h4>ios</h4>
                                        <h3>102K</h3>
                                    </div>
                                </div>

                                {/* Start Sinhle app */}
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <i className="zmdi zmdi-cloud-download"></i>
                                        <h4>Download</h4>
                                        <h3>102K</h3>
                                    </div>
                                </div>

                                {/* Start Single app */}
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <i className="zmdi zmdi-android"/>
                                        <h4>Android</h4>
                                        <h3>102K</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-product-image text-right">
                                <img src={banner} alt="App Landing"/>
                            </div>
                        </div>
                    </div>
                </div>
                <ParticlesBg color="#ffffff" type="cobweb" config={config} bg={true} />

            </div>
        )
    }
}

export default HeroOlive;








