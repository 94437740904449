import React, { Component } from "react";



class Service extends Component{
    render(){
        let data = [
            {
                icon: 'zmdi zmdi-graduation-cap',
                title: 'ELEVES',
                desc: `Pour vous, élève partenaire : Vous
                êtes élève de chaque professeur
                de votre pays. Chaque professeur
                peut vous aider à mieux
                comprendre vos cours et à traiter
                vos exercices.
                `
            },

            {
                icon: 'zmdi zmdi-brush',
                title: 'PROFESSEUR',
                desc:`
                Pour vous, professeur partenaire : chaque élève de votre pays peut vous occuper pendant vos temps libre
                    et en retour vous rémunérer pour votre appui et disponibilité. Vous pouvez gagner le prix Nusronla
                    qui serait organisé chaque année pour encourager les meilleur professeur qui aident plus les élèves.
                `
            },

            {
                icon: 'zmdi zmdi-balance',
                title: 'ETABLISSEMENT',
                desc: `
                Pour vous, établissement partenaire : votre établissement bénéficiera de la conception d'un site web
                pour votre visibilité. Vous pouvez gagner au prix Nusronla du meilleur établissement de l'année.                
                `
            }
        ];
        let DataList = data.map((val, i) => {
            return(
                <div className="col-lg-4 service-column" key={i}>
                    <div className="single-service text-center">
                        <div className="service-icon">
                            <i className={`${val.icon}`}></i>
                        </div>
                        <h4 className="title">{val.title}</h4>
                        <p className="desc">{val.desc}</p>
                    </div>
                </div>
            )
        });

        return (
            <div>
                {/* Start Service Area */}
                <div className={`service-area ${this.props.horizontal}`}>
                    <div className="container">
                        <div className="row">
                            {DataList}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}
            </div>
        )
    }
}

export default Service;

