import React ,  { Component } from "react";
import logo from "../assets/images/logo/logo.png";
import img from "../assets/images/app/phone1.png";


class About extends Component{
    render(){
        return(
            <div className={`app-about ${this.props.horizontalabout}`}  id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2>A PROPOS DE <span className="theme-color">NUSRONLA</span></h2>
                                <img width={70}  src={logo} alt="app landing"/>
                                <p>
                                    Avec l'application mobile Nusronla, vous avez avec vous, tout le programme éducatif secondaire de
                                    votre pays, ainsi que toutes les épreuves passées de tous les niveau et de toutes les séries de chaque
                                    établissement scolaire agréé de votre pays.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5 offset-lg-1 mt--40">
                            <div className="about-thumbnail mr--35">
                                <img src={img} alt="App Landing"/>
                                {/* <img className="image-2" src={require('../assets/images/app/mobile-2.png').default} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/mobile-3.png').default} alt="App Landing"/> */}
                            </div>
                        </div>
                        <div className="col-lg-6 mt--40">
                            <div className="about-content">
                                <h2 className="title"><span className="theme-color">SES ATOUTS </span></h2>
                                <p className="mt-1">
                                    Pour vous, élève partenaire : Vous êtes élève de chaque professeur de votre pays. Chaque professeur
                                    peut vous aider à mieux comprendre vos cours et à traiter vos exercices.
                                </p>
                                <p className="mt-1">
                                    Pour vous, professeur partenaire : chaque élève de votre pays peut vous occuper pendant vos temps libre
                                    et en retour vous rémunérer pour votre appui et disponibilité. Vous pouvez gagner le prix Nusronla
                                    qui serait organisé chaque année pour encourager les meilleur professeur qui aident plus les élèves.
                                </p>
                                <p className="mt-1">
                                Pour vous, établissement partenaire : votre établissement bénéficiera de la conception d'un site web
                                pour votre visibilité. Vous pouvez gagner au prix Nusronla du meilleur établissement de l'année.
                                </p>
                                <div className="about-buttons">
                                    <button type="button" className="button-default button-olive button-border">Télécharger</button>
                                    <a className="button-default button-border" href="#about" role="button">Lire plus</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;











