import React, { Component } from "react";
import img from "../assets/images/app/eleve5.png";
import img2 from "../assets/images/app/eleve7.png";
import img3 from "../assets/images/app/eleve2.png";


class Screenshot extends Component { 
    render(){
        return (
            <div className="screenshot-area pt--120" id="screenshots">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--80">
                                <h2>APP <span className="theme-color">SCREENSHOTS</span></h2>
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1">
                            <div className="screenshot-carousel" id="screenshot-carousel" data-carousel-3d>
                            <img className="image-1" src={img} alt="App Screenshot"/>
                            <img src={img2} alt="App Screenshot"/>
                            <img  src={img3} alt="App Screenshot"/>
                            <img src={img} alt="App Screenshot"/>
                            <img src={img2} alt="App Screenshot"/>
                            <img  src={img3} alt="App Screenshot"/>
                            
                            
                            

                                {/* <img className="image-1" src={require('../assets/images/app/screenshot-01.png').default} alt="App Screenshot"/>
                                <img className="image-1" src={require('../assets/images/app/screenshot-01.png').default} alt="App Screenshot"/>
                                <img className="image-1" src={require('../assets/images/app/screenshot-01.png').default} alt="App Screenshot"/>
                                <img className="image-1" src={require('../assets/images/app/screenshot-01.png').default} alt="App Screenshot"/>
                                <img className="image-1" src={require('../assets/images/app/screenshot-01.png').default} alt="App Screenshot"/>
                                <img className="image-1" src={require('../assets/images/app/screenshot-01.png').default} alt="App Screenshot"/> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Screenshot;










