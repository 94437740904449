import React ,  { Component } from "react";
import img from "../assets/images/logo/logo.png";



class Feature extends Component{
    render(){
       let data = [
           
        {
            icon: 'zmdi zmdi-collection-text',
            title: 'Anale d\'épreuve',
            desc: `
                Nusronla dispose d'une grande bibliothèque riche en épreuves de toute matières et de toute classes. Nusronla met à disposition de ses utilisateurs sa grande bibliothèque.
                Les élèves utilisant l'application Nusronla ont accès à toutes les épreuves de tous les établissements de leur pays.
            `
        },

        {
            icon: 'zmdi zmdi-sun',
            title: "Assistance d'élaboration de sujet d'évaluation (professeur)",
            desc: `Grâce à son IA révolutionnaire basé sur des algorithmes de recherche avancée, Nusronla assistent les professeurs qui l'utilisent dans la composision de leurs differents sujets d'évaluations de leurs élèves`
        },

        {
            icon: 'zmdi zmdi-brush',
            title: 'Élaboration de TD (professeur)',
            desc: `Nusronla permet aux professeurs d'élaborer plus facilement des TD Pour leurs élèves.`
        },

        {
            icon: 'zmdi zmdi-desktop-mac',
            title: 'Assistance de révision (élève)',
            desc: `Avec sa grande bibliothèque, Nusronla rend les révisions des élèves plus facile que jamais en leurs proposant un large panoplie d'exercices.`
        },

        {
            icon: 'zmdi zmdi-washing-machine',
            title: 'Assistance ',
            desc: `Grâce à sa fonctionnalité d'assistance,  Nusronla assiste les élèves lorsqu'ils rencontrent des difficultés en leurs offrant une assisance personnalisée.`
        }
       ] 

       let DataList = data.map((val , i) => {
           return(
               /* Start Single Feature */
               <div className="feature" key={i}>
                   <div className="feature-icon">
                       <i className={`${val.icon}`}/>
                   </div>
                   <div className="content">
                       <h4 className="title">{val.title}</h4>
                       <p className="desc">{val.desc}</p>
                   </div>
               </div>
               /* End Single Feature */
           )
       })

       return(
           <div className= {`feature-area feature-bg-image pb--50 ${this.props.horizontalfeature}`} id="features">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2> FONCTIONALITE </h2>
                                <img width={70} src={img} alt="App Landing"/>
                            </div>
                       </div>
                   </div>
                   <div className="row mt--30">
                       <div className="col-lg-7 offset-lg-5">
                            <div className="feature-list">
                                {DataList}
                            </div>
                       </div>
                   </div>
               </div>
           </div>
        )
    }
}

export default Feature;
